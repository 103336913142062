<header class="header">
  <div class="wide-full-section p-t-5 p-b-5">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
      <a routerLink="/">
        <img alt="체크팀" ngClass.xs="logo-xs-img" ngClass.gt-xs="logo-img"
             src="/assets/img/bi/bi-text.svg" src.gt-xs="/assets/img/bi/bi-text.svg">
      </a>

      <div fxLayout="row" fxLayoutGap.gt-xs="12px" fxLayoutGap.xs="10px">
        @if (!mobile()) {
          <div fxLayout="row" fxLayoutGap="15px">
            <button [matMenuTriggerFor]="analysis" mat-button fxLayoutAlign="start center">
              <span class="c-header-lg">부동산 권리분석</span>
              <span class="m-l-2 grade-primary-xs f-11 p-h-6 bg-badge">beta</span>
              <span class="material-symbols-outlined mat-icon-size-25">arrow_drop_down</span>
            </button>
            <mat-menu #analysis="matMenu" xPosition="before">
              <button mat-menu-item routerLink="/analysis/list">권리분석 보고서</button>
              <button mat-menu-item routerLink="/analysis">신규 권리분석</button>
              <button mat-menu-item routerLink="/analysis/info">서비스 안내</button>
            </mat-menu>

            <button [matMenuTriggerFor]="myHouse" mat-button>
              <span class="c-header-lg">부동산 등기변경 알림</span>
              <span class="material-symbols-outlined c-gray mat-icon-size-25">arrow_drop_down</span>
            </button>
            <mat-menu #myHouse="matMenu" xPosition="before">
              <button mat-menu-item routerLink="/house/list">등기변경 리스트</button>
              <button [queryParams]="{type: 'MY_HOUSE'}" mat-menu-item routerLink="/house/register">서비스 신청</button>
              <button mat-menu-item routerLink="/house/batch">일괄 신청</button>
              <button mat-menu-item routerLink="/house/extends">일괄 연장</button>
              <button mat-menu-item routerLink="/coupon">쿠폰 구입</button>
              <button mat-menu-item routerLink="/info/my-house">서비스 안내</button>
            </mat-menu>

            <button [matMenuTriggerFor]="openHouse" mat-button>
              <span class="c-header-lg">오픈부동산</span>
              <span class="material-symbols-outlined c-gray mat-icon-size-25">arrow_drop_down</span>
            </button>
            <mat-menu #openHouse="matMenu" xPosition="before">
              <button mat-menu-item routerLink="/house/open">오픈부동산 알림신청</button>
              <button [queryParams]="{type: 'OPEN_HOUSE'}" mat-menu-item routerLink="/house/register">오픈부동산 등록</button>
              <button mat-menu-item routerLink="/house/extends">일괄 연장</button>
              <button mat-menu-item routerLink="/coupon">쿠폰 구입</button>
              <button mat-menu-item routerLink="/info/open-house">서비스 안내</button>
            </mat-menu>

            <button mat-button class="m-t-m2 c-header-lg">
              <a routerLink="/calculator">
                부동산 계산기
              </a>
            </button>

            <button mat-button class="m-t-m2 c-header-lg">
              <a routerLink="/info/pricing">
                <span>이용금액</span>
              </a>
            </button>

            <button mat-button>
              <a [matMenuTriggerFor]="serviceMenu">
                <span class="c-header-lg">서비스</span>
                <span class="material-symbols-outlined mat-icon-size-25">arrow_drop_down</span>
              </a>
            </button>

            <mat-menu #serviceMenu="matMenu" xPosition="before">
              <button mat-menu-item routerLink="/info/faq">자주하는 질문</button>
              <button [queryParams]="{type: 'EVENT'}" mat-menu-item routerLink="/info/events">이벤트</button>
              <button [queryParams]="{type: 'NOTICE'}" mat-menu-item routerLink="/info/notice">공지사항</button>
              <button [queryParams]="{type: 'INQUIRE'}" mat-menu-item routerLink="/inquire">1 : 1 문의</button>
              <button [queryParams]="{type: 'ERROR'}" mat-menu-item routerLink="/inquire">오류 신고</button>
              <button [queryParams]="{type: 'SUGGEST'}" mat-menu-item routerLink="/inquire">기능 요청</button>
              <button mat-menu-item routerLink="/info/company">회사소개</button>
            </mat-menu>
          </div>
        }


      </div>

      <div>
        @if (login()) {
          <div fxLayout="row">
            <button mat-button routerLink="/notification">
              <lord-icon class="va-middle" ngClass.gt-xs="img-size-wh-27" ngClass.xs="img-size-wh-27"
                         src="assets/json/notification-bell-outline.json" trigger="hover"></lord-icon>
              <span [matBadge]="countNotifications"></span>
            </button>

            <div>
              <button mat-button [matMenuTriggerFor]="myHome" ngClass.xs="m-r-m10">
                <lord-icon class="va-middle" ngClass.gt-xs="img-size-wh-27" ngClass.xs="img-size-wh-27" src="assets/json/account-outline.json"
                           trigger="hover"></lord-icon>
              </button>
              <mat-menu #myHome="matMenu" xPosition="before">
                <button mat-menu-item routerLink="/home">마이페이지</button>
                <button mat-menu-item routerLink="/home/info">회원정보변경</button>
                <button mat-menu-item routerLink="/home/sns">SNS 로그인 설정</button>
                <button mat-menu-item routerLink="/home/purchase">결제내역</button>
                <button mat-menu-item routerLink="/home/coupon">쿠폰함</button>
                <button mat-menu-item (click)="clickLogout()">로그아웃</button>
              </mat-menu>
            </div>
          </div>

        } @else {
          <div fxLayout="row" fxLayoutGap="10px">
            <button ngClass.gt-xs="bg-gray-light hover-primary c-header-lg" mat-flat-button ngClass.xs="f-11 bg-gray-light f-w-700" routerLink="/join">회원가입</button>
            <button (click)="clickLogin()" ngClass.gt-xs="bg-black hover-primary c-header-lg" ngClass.xs="f-11 bg-black f-w-700" mat-flat-button>
              로그인
            </button>
          </div>
        }
      </div>

    </mat-toolbar>
  </div>
</header>
