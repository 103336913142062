<div *ngIf="notice" ngClass.gt-xs="bg-gray-light" ngClass.xs="bg-white p-b-15">
  <section class="banner-xs p-b-2">
    <div class="cursor" ngClass.xs="p-l-20 p-r-5">
      <div (click)="openNotice()" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="7px">
        <div class="notice-badge">긴급공지</div>

        <div class="f-12 lh-1-7 f-600" ngClass.gt-xs="f-13 f-600" ngClass.xs="lh-1-8">
          {{ notice.title }}
          <span *ngIf="isIrosNotice" ngClass.gt-xs="p-l-10" ngClass.xs="c-gray">
            @if (isMobile) {
              <br/>
            }
            기간ㅣ{{ notice.displayMainStartAt | date: 'yyyy.MM.dd. HH : mm' }}
            - {{ notice.displayMainEndAt | date: 'MM.dd. HH : mm' }}</span>
        </div>
      </div>
    </div>
  </section>
</div>
