import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import locale from '@angular/common/locales/ko';
import {registerLocaleData} from "@angular/common";

if (environment.production) {
  enableProdMode();
}

registerLocaleData(locale, "ko");

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

