import {Component, OnDestroy} from '@angular/core';
import {NoticeService} from "../../../domain/notice/notice.service";
import {DatePipe, NgIf} from "@angular/common";
import {FlexLayoutModule, MediaChange, MediaObserver} from "@ngbracket/ngx-layout";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {InfoDialogComponent} from "../../../shared/dialog/info-dialog/info-dialog.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-main-notice',
  standalone: true,
  imports: [
    FlexLayoutModule,
    DatePipe,
    NgIf,
    MatDialogModule
  ],
  templateUrl: './main-notice.component.html',
  providers: [NoticeService]
})
export class MainNoticeComponent implements OnDestroy {
  notice: any;
  isMobile = false;
  isIrosNotice = false;
  private readonly mediaWatcher: Subscription;

  constructor(private readonly media: MediaObserver,
              private readonly noticeService: NoticeService,
              private readonly matDialog: MatDialog) {
    this.mediaWatcher = this.media.asObservable()
      .subscribe((change: MediaChange[]) => {
        this.isMobile = change[0].mqAlias === 'xs';
      });

    this.noticeService.getMainPageNotice()
      .subscribe(response => {
        if (response) {
          this.notice = response;
          this.isIrosNotice = this.notice.title === "인터넷등기소 점검으로 인한 서비스 제한 안내";
        }
      }).unsubscribe();
  }

  openNotice() {
    if (this.notice) {
      this.matDialog.open(InfoDialogComponent, {
        data: {
          title: this.notice.title,
          content: this.notice.webContent.replaceAll("<br>", "\n").replaceAll(/(<([^>]+)>)/ig, '')
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.mediaWatcher) {
      this.mediaWatcher.unsubscribe();
    }
  }
}
