export const KO_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY.MM.DD',
  },
  display: {
    dateInput: 'YYYY.MM.DD',
    monthYearLabel: 'YYYY년 MM월',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MM'
  },
};
