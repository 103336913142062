import {Component} from '@angular/core';
import {NGXLogger} from "ngx-logger";
import {NativeService} from "../../../_service/native.service";
import moment from "moment";
import {CommonModule} from "@angular/common";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {RouterLink} from "@angular/router";

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  imports: [
    CommonModule,
    ExtendedModule,
    FlexModule,
    MatMenuModule,
    MatTooltipModule,
    RouterLink]
})
export class FooterComponent {
  showStoreLink = false;
  currentYear = moment().get('year');

  constructor(private readonly log: NGXLogger,
              private readonly nativeService: NativeService) {
    this.showStoreLink = !this.nativeService.isNative();
  }

  openChat(url: string) {
    this.log.debug('Chat Url', url);
    window.open(url, '1 : 1', 'scrollbars=1, resizable=1, width=486, height=745');
  }

  openFamilySite(url: string) {
    window.open(url, '_blank');
  }
}
