import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from './main.component';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {defineElement} from "lord-icon-element";
import lottie from "lottie-web";
import {NoticeService} from "../../domain/notice/notice.service";
import {InfoDialogModule} from "../../shared/dialog/info-dialog/info-dialog.module";
import {SwiperModule} from "../../shared/swiper/swiper.module";
import {MainNoticeComponent} from "./main-notice/main-notice.component";
import {FooterComponent} from "./footer/footer.component";
import {HouseService} from "../../domain/house/service/house.service";
import {AnalysisService} from "../../analysis/_service/analysis.service";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [MainComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    SwiperModule,
    InfoDialogModule,
    MainNoticeComponent,
    FooterComponent
  ],
  providers: [NoticeService, HouseService, AnalysisService],
})
export class MainModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
