<app-header [login]="isLogin" [mobile]="mobile"/>

<div id="root-container" ngClass.gt-xs="p-t-70" ngClass.xs="p-t-60">
  <router-outlet></router-outlet>
</div>

@if (mobile) {
  <app-bottom [currentUrl]="currentUrl"/>
}

<ngx-spinner [fullScreen]="true" class="img-size-120 text-center"
             template="<img src='assets/img/gif/loading-progress.svg'/>">
  <p ngClass.gt-xs="p-t-10 lh-1-7 f-30 f-w-600" ngClass.xs="p-t-30 lh-1-7 f-16 f-w-600" style="color: white">
    정보를 확인하고 있습니다.<br>
    <span class="f-15 600">잠시만 기다려주세요 !</span>
  </p>
</ngx-spinner>

<app-scroll/>
