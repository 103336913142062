<div class="bg-gray-light">
  <section class="banner" fxLayout="column" ngClass.gt-xs="p-v-50" ngClass.xs="p-h-25 p-v-20">
    <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutGap.xs="10px">
      <div fxFlex fxFlex.gt-xs="60">
        <div class="c-gray-deep" fxLayout="column" fxLayoutGap="2px" ngClass.xs="f-12">
          <div class="f-600 m-b-10 c-gray-dark mat-subtitle-2" routerLink="/introduce/company">주식회사 로얼라이언스</div>
          <div>대표 김대훈 ㅣ 사업자번호 522-81-00931 ㅣ 통신판매번호 2018-서울강남-00730</div>
          <div>서울 강남구 영동대로76길 10 2층 로얼라이언스</div>
          <div>ct&#64;lawalliance.co.kr ㅣ 전화 02-555-0324</div>
          <div>개인정보관리책임자 김대훈</div>

          <div ngClass.gt-xs="p-v-10 f-13 c-gray-deep p-v-10 f-600" ngClass.xs="m-b-20 f-12">
            <span class="cursor" routerLink="/terms/service">이용약관</span>&nbsp;ㅣ
            <span class="cursor" routerLink="/terms/privacy">개인정보 취급·처리 방침</span> ㅣ&nbsp;
            <span #tooltip="matTooltip" (click)="tooltip.toggle()"
                  matTooltip="본 웹사이트에 게시된 이메일 주소가 전자우편 수집프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며 이를 위반 시 ‘정보통신망 이용촉진 및 정보보호등에 관한 법률’ 에 의해 처벌 받을 수 있습니다."
                  matTooltipPosition="above">이메일 무단수집거부</span>
          </div>
        </div>
      </div>

      <div fxFlex fxFlex.gt-xs="20">
        <div ngClass.gt-xs="p-t-37" class="mat-body-2 f-600 ff-sc6 cursor" fxLayout="column" fxLayoutGap.gt-xs="10px" fxLayoutGap.xs="15px">
          <div [queryParams]="{type: 'ERROR'}" id="error" routerLink="/inquire">긴급 오류신고</div>
          <div [queryParams]="{type: 'SUGGEST'}" id="suggest" routerLink="/inquire">서비스 기능요청</div>
          <div [queryParams]="{type: 'INQUIRE'}" id="inquire" routerLink="/inquire">1 : 1 문의</div>
          <div fxShow fxHide.gt-xs (click)="openChat('https://pf.kakao.com/_SRues/chat')" routerLink="/info/events">카카오톡 문의</div>
          <div fxShow fxHide.gt-xs (click)="openChat('https://talk.naver.com/W4GHRN?ref=https%3A%2F%2Fcheckteam.co.kr')" routerLink="/info/events">네이버톡 문의</div>
          <div [queryParams]="{type: 'NOTICE'}" id="notice" routerLink="/info/notice">공지사항</div>
          <div [queryParams]="{type: 'EVENT'}" routerLink="/info/events">이벤트</div>
        </div>
      </div>



      <div fxHide fxShow.gt-xs fxFlex="20" fxLayout="column" fxLayoutAlign="start end" ngClass.gt-xs="p-t-20">
        <div fxLayout="row" fxLayoutGap="10px" class="p-t-20">
          <div (click)="openChat('https://pf.kakao.com/_SRues/chat')" fxFlex>
            <div class="cursor">
              <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
                <img alt="카카오톡 문의" class="img-size-45" src="/assets/img/platform/sns-kakao-black.svg">
                <div class="f-13 f-600">카카오톡 문의</div>
              </div>
            </div>
          </div>

          <div (click)="openChat('https://talk.naver.com/W4GHRN?ref=https%3A%2F%2Fcheckteam.co.kr')" fxFlex>
            <div class="cursor">
              <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
                <img alt="네이버톡 문의" class="img-size-45" src="/assets/img/platform/sns-naver-black.svg">
                <div class="f-13 f-600">네이버톡 문의</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="20px" class="p-v-30">
      <div ngClass.xs="f-12">
        Copyright © {{currentYear}} CHECK teamㅣ체크팀 <span class="p-l-5">LawAlliance</span>
      </div>

      <div fxHide fxShow.gt-xs>
        <div [matMenuTriggerFor]="serviceMenu" ngClass.gt-xs="f-14 f-600 cursor" ngClass.xs="f-13 f-600">
          패밀리 사이트
        </div>
        <mat-menu #serviceMenu="matMenu">
          <button (click)="openFamilySite('https://lawfin.co.kr')" mat-menu-item>
            로핀
          </button>
          <button (click)="openFamilySite('https://bigcrowd.co.kr')" mat-menu-item>
            빅크라우드
          </button>
        </mat-menu>
      </div>
    </div>
  </section>
</div>
