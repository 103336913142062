import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {UserService} from './domain/user/service/user.service';
import {NGXLogger} from 'ngx-logger';
import {User} from './domain/user/model/user';
import {NavigationEnd, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MediaChange, MediaObserver} from '@ngbracket/ngx-layout';
import {Subject} from 'rxjs';
import {NotificationService} from './domain/notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  mobile = true;
  enableRefresh = true;
  isLogin: boolean = false;
  targetElement: Element | undefined;
  currentUrl: string = '/';

  constructor(private readonly log: NGXLogger,
              private readonly media: MediaObserver,
              private readonly router: Router,
              private readonly matDialog: MatDialog,
              private readonly userService: UserService,
              private readonly notificationService: NotificationService) {
    this.media.asObservable().subscribe((change: MediaChange[]) => {
      this.mobile = change[0].mqAlias === 'xs';
    });

    this.userService.changeUser()
      .subscribe((user: User | undefined) => {
        this.isLogin = user !== undefined;
      });

    this.userService.checkSession()
      .subscribe(result => {
        this.isLogin = result;
        this.checkCountNotifications();
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.log.debug('Changed Url: ', event.url)
        if (this.isLogin) {
          this.checkCountNotifications();
        }

        this.currentUrl = event.url;
      }
    });
  }

  @HostListener('window:scroll', ['$event.target'])
  onWindowScroll() {
    this.enableRefresh = window.scrollY <= 0
  }

  ngOnInit() {
    this.targetElement = document.querySelector('app-root')!;
  }

  myRefreshEvent(event: Subject<any>) {
    const previousUrl = this.router.url;

    this.router.navigateByUrl('/refresh', {skipLocationChange: true})
      .then(() => {
        const refreshTimer = setTimeout(() => {
          this.router.navigateByUrl(previousUrl)
            .then(() => {
              event.next('');
              clearTimeout(refreshTimer);
            });
        }, 300);
      });
  }

  ngAfterViewInit(): void {
    this.matDialog.afterOpened
      .subscribe(() => {
        this.log.debug('Dialog After Opened');
        if (this.mobile) {
          this.enableRefresh = false;
        }
      });

    this.matDialog.afterAllClosed
      .subscribe(() => {
        this.log.debug('Dialog After Closed');
        if (this.mobile) {
          this.enableRefresh = true;
        }
      });
  }

  private checkCountNotifications() {
    if (this.isLogin) {
      this.notificationService.countNotifications()
        .subscribe(() => {
        })
    }
  }
}
