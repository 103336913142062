import {Component} from '@angular/core';
import {NativeService} from '../../_service/native.service';
import {NGXLogger} from "ngx-logger";
import {UserService} from "../../domain/user/service/user.service";
import {User} from "../../domain/user/model/user";
import {AnalysisService} from "../../analysis/_service/analysis.service";
import {HouseService} from "../../domain/house/service/house.service";

declare let window: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  showStoreLink = false;
  partnerLogos = [
    'assets/img/partner/logo-round-8percent.png',
    'assets/img/partner/logo-round-cocktailfunding.png',
    'assets/img/partner/logo-round-zoomfund.png',
    'assets/img/partner/logo-round-oasisfund.png',
    'assets/img/partner/logo-round-yfund.png',
    'assets/img/partner/logo-round-leadingplus.png',
    'assets/img/partner/logo-round-acefunding.png',
    'assets/img/partner/logo-round-fmfunding.png',
    'assets/img/partner/logo-round-dailyfunding.png',
    'assets/img/partner/logo-round-miracle.png',
    'assets/img/partner/logo-round-namo.png',
    'assets/img/partner/logo-round-daonfunding.png',
    'assets/img/partner/logo-round-smartfunding.png',
    'assets/img/partner/logo-round-titaninvest.png'
  ]
  isLogin: boolean = false;
  counts = {
    house: 0,
    analysis: 0,
    analysisPending: 0
  }

  constructor(private readonly log: NGXLogger,
              private readonly userService: UserService,
              private readonly houseService: HouseService,
              private readonly analysisService: AnalysisService,
              private readonly nativeService: NativeService) {
    this.log.debug('START Main Component');
    this.showStoreLink = !this.nativeService.isNative();

    this.userService.changeUser()
      .subscribe((user: User | undefined) => {
        this.isLogin = user !== undefined;
      });

    this.userService.checkSession()
      .subscribe(result => {
        this.isLogin = result;
        if (this.isLogin) {
          this.analysisService.getCounts()
            .subscribe(response => {
              this.log.debug('권리분석 수', response);
              this.counts.analysis = response.completeCount + response.pendingCount;
              this.counts.analysisPending = response.pendingCount;
            })
        }
      });

    this.houseService.getHouseSummary()
      .subscribe(response => {
        this.log.debug('부동산 수', response);
        this.counts.house = response.myHouse + response.openHouse + response.openSubscribe;
      })
  }

  openPlayStore() {
    window.open('https://play.google.com/store/apps/details?id=kr.co.lawalliance.checkteam')
  }

  openAppStore() {
    if (this.nativeService.isiOS) {
      window.open('itms-apps://itunes.apple.com/kr/app/apple-store/id1578403569?mt=8');
    } else {
      window.open('https://itunes.apple.com/kr/app/apple-store/id1578403569?mt=8');
    }
  }
}
