<app-main-notice/>

<!--main visual start-->
<div ngClass.gt-xs="p-t-20 p-b-10 main-top-wave">
  <section class="main-visual-section banner-xs" ngClass.xs="m-b-50 main-top-wave-xs">
    <div ngClass.xs="p-b-25">

      <app-swiper paginationType="bullets" swiperId="bullets">
        <swiper-slide fxLayout="column" ngClass.gt-xs="p-l-60" routerLink="/analysis/info" fxHide.gt-xs fxShow>
          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between start"
               fxLayoutGap.xs="20px">
            <div fxFlex.xs fxFlex.gt-xs="70">
              <a href="https://forms.gle/wCSsPUSMbyMATCai7" target="_blank">
              <div ngClass.gt-xs="p-v-40" ngClass.xs="p-h-22 p-v-30">
                <div>
                  <div ngClass.gt-xs="mat-subtitle-2 m-b-10 ff-sc-7"
                       ngClass.xs="mat-body-1 ff-sc-6 m-b-5">
                    부동산 권리분석 <span class="p-h-10 p-t-5 p-b-3 brd-20 bg-gr-mint-purple f-12">BETA</span>
                    <span class="m-l-5 p-h-10 p-t-6 p-b-3 brd-20 bg-purple f-12 c-white">무료 분석 쿠폰 받기</span>
                  </div>
                  <div ngClass.gt-xs="mat-headline-6 m-b-10"
                       ngClass.xs="mat-subtitle-1 m-b-10">
                    부동산 권리분석 서비스를 시작합니다.
                  </div>
                  <div ngClass.gt-xs="mat-subtitle-1 lh-1-6 m-b-25"
                       ngClass.xs="mat-body-1 f-600 ff-sc-5  m-b-10 lh-1-5">
                    권리분석을 베타 서비스로 미리 경험해 보세요.
                  </div>

                  <div fxShow fxHide.gt-xs fxLayoutAlign="start end" class="m-l-m15 m-b-m10">
                    <img alt="권리분석" class="img-size-180 icon-tran-scalex"
                         src="assets/img/character/analyzes-statistical-data.svg">
                  </div>

                </div>
              </div>
              </a>
            </div>
          </div>
        </swiper-slide>


        <swiper-slide fxLayout="column" ngClass.gt-xs="p-l-60" routerLink="/analysis/info">
          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between start"
               fxLayoutGap.xs="20px">
            <div fxFlex.xs fxFlex.gt-xs="70">
              <div ngClass.gt-xs="p-v-40" ngClass.xs="p-h-22 p-v-30">
                <div>
                  <div ngClass.gt-xs="mat-subtitle-2 m-b-10 ff-sc-7"
                       ngClass.xs="mat-body-1 ff-sc-6 m-b-5">
                    부동산 권리분석
                  </div>
                  <div ngClass.gt-xs="mat-headline-6 m-b-10"
                       ngClass.xs="mat-subtitle-1 m-b-10">
                    부동산 분석! 어렵고 걱정되시죠!
                  </div>
                  <div ngClass.gt-xs="mat-subtitle-1 lh-1-6 m-b-25"
                       ngClass.xs="mat-body-1 f-600 ff-sc-5 m-b-30 lh-1-5">
                    체크팀으로 <span ngClass.gt-xs="f-600 bg-neon-pink bg-gr-accent-mint"
                                ngClass.xs="mat-subtitle-3 f-600 bg-neon-pink bg-gr-accent-mint lh-1-8">내일의 위험까지 분석</span> 하세요.
                  </div>
                  <div fxLayout="column" class="p-b-20" fxHide fxShow.gt-xs>
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">담보대출 분석</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">전세보증금 진단</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">경매 시뮬레이션</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">자산가치 분석</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">매매계약 진단</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">가맹점 담보가치 분석</div>
                    </div>
                  </div>
                  <div fxShow fxHide.gt-xs fxLayoutAlign="start end" class="m-t-m20 m-l-m10 m-b-m20">
                    <img alt="권리분석" class="img-size-180"
                         src="assets/img/character/analyzes-the-data-primary.svg">
                  </div>

                  <div fxHide fxLayout="column">
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">부동산담보대출 분석</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">전세보증금 진단</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">경매 시뮬레이션</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">자산가치 분석</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">매매계약 진단</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">가맹점 담보 분석</div>
                    </div>
                  </div>
                  <div fxHide fxShow.gt-xs ngClass.gt-xs="mat-body-1 cursor p-l-5"><u>&nbsp;자세히 보기&nbsp;</u></div>
                </div>
              </div>
            </div>

            <div fxHide fxShow.gt-xs fxFlex.gt-xs="30" fxLayoutAlign.gt-xs="center start">
              <div>
                <img alt="부동산권리분석" class="img-size-250 icon-tran-scalex"
                     src="assets/img/character/analyzes-the-data-primary.svg">
              </div>
            </div>
          </div>
        </swiper-slide>


        <swiper-slide fxLayout="column" ngClass.gt-xs="p-l-60" routerLink="/analysis/info">
          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between start"
               fxLayoutGap.xs="20px">
            <div fxFlex.xs fxFlex.gt-xs="70">
              <div ngClass.gt-xs="p-v-40" ngClass.xs="p-h-22 p-v-30">
                <div>
                  <div ngClass.gt-xs="mat-subtitle-2 m-b-10"
                       ngClass.xs="mat-body-1 ff-sc-6 m-b-5">
                    부동산 권리분석
                  </div>
                  <div ngClass.gt-xs="mat-headline-6 m-b-10"
                       ngClass.xs="mat-subtitle-1 m-b-10">
                    우리집 전세보증금 분석 !
                  </div>
                  <div ngClass.gt-xs="mat-subtitle-1 lh-1-6 m-b-25"
                       ngClass.xs="mat-body-1 f-600 ff-sc-5 m-b-30 lh-1-5">
                    보증금 <span ngClass.gt-xs="f-600 bg-neon-pink bg-pink"
                              ngClass.xs="mat-subtitle-3 f-600 bg-neon-pink bg-gr-mint-pink">안전성과 위험권리를 미리 확인</span> 하세요.
                  </div>
                  <div fxLayout="column" class="p-b-20" fxHide fxShow.gt-xs>
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
                      <div class="gray-bg-chip-lg m-b-7 bg-pink-light">전세 보증금 안전성</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-pink-light">법원 예상 배당표</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-pink-light">권리 시뮬레이션</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-pink-light">위험 권리 확인</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-pink-light">전세 시세</div>
                    </div>
                  </div>
                  <div fxShow fxHide.gt-xs fxLayoutAlign="start end" class="m-t-m20 m-l-m20 m-b-m20">
                    <img alt="권리분석" class="img-size-180"
                         src="assets/img/character/protects-personal-pink.svg">
                  </div>
                  <div fxHide fxLayout="column">
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <div class="gray-bg-chip-xs m-b-7 bg-pink-light">전세 보증금 안전성</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-pink-light">법원 예상 배당표</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-pink-light">권리 시뮬레이션</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-pink-light">위험 권리 확인</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-pink-light">전세 시세</div>
                    </div>
                  </div>
                  <div fxHide fxShow.gt-xs ngClass.gt-xs="mat-body-1 cursor p-l-5"><u>&nbsp;자세히 보기&nbsp;</u></div>
                </div>
              </div>
            </div>

            <div fxHide fxShow.gt-xs fxFlex.gt-xs="30" fxLayoutAlign.gt-xs="center start">
              <img alt="부동산권리분석" class="img-size-250 icon-tran-scalex" src="assets/img/character/protects-personal-pink.svg">
            </div>
          </div>
        </swiper-slide>

        <swiper-slide fxLayout="column" ngClass.gt-xs="p-l-60" routerLink="/analysis/info">
          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between start">
            <div fxFlex fxFlex.gt-xs="70">
              <div ngClass.gt-xs="p-v-40" ngClass.xs="p-h-22 p-v-30">
                <div>
                  <div ngClass.gt-xs="mat-subtitle-2 m-b-10"
                       ngClass.xs="mat-body-1 ff-sc-6 f-600 m-b-5">
                    부동산 권리분석
                  </div>
                  <div ngClass.gt-xs="mat-headline-6 m-b-10"
                       ngClass.xs="mat-subtitle-1 m-b-10">
                    <span fxHide fxShow.gt-xs>부동산 등기부</span>등본 확인에서 배당분석까지! 55초!
                  </div>
                  <div ngClass.gt-xs="mat-subtitle-1 lh-1-6 m-b-25"
                       ngClass.xs="mat-body-1 f-600 ff-sc-5 m-b-30 lh-1-5">
                    분석 업무를 <span ngClass.gt-xs="f-600 bg-neon-pink bg-primary"
                                 ngClass.xs="mat-subtitle-3 f-600 bg-neon-pink bg-gr-mint-primary">빠르고 효율적으로 자동화</span> 하세요.
                  </div>
                  <div fxLayout="column" class="p-b-20" fxHide fxShow.gt-xs>
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">권리분석 보고서</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">법원 예상 배당표</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">보고서 다운로드</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">등본 자동발급</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-primary-light">시세 확인</div>
                    </div>
                  </div>
                  <div fxShow fxHide.gt-xs fxLayoutAlign="start end" class="m-t-m20 m-l-m20 m-b-m20">
                    <img alt="권리분석" class="img-size-170"
                         src="assets/img/character/sorts-analyzes-data-accent.svg">
                  </div>
                  <div fxHide fxLayout="column">
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">권리분석 보고서</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">법원 예상 배당표</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">보고서 다운로드</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">등본 자동발급</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary-light">시세 확인</div>
                    </div>
                  </div>

                  <div fxHide fxShow.gt-xs ngClass.gt-xs="mat-body-1 cursor p-l-5"><u>&nbsp;자세히 보기&nbsp;</u></div>
                </div>
              </div>
            </div>

            <div fxHide fxShow.gt-xs fxFlex.gt-xs="30" fxLayoutAlign.gt-xs="center start">
              <div>
                <img alt="부동산권리분석" class="img-size-250 icon-tran-scalex" src="assets/img/character/sorts-analyzes-data-accent.svg">
              </div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide fxLayout="column" ngClass.gt-xs="p-l-60" routerLink="/info/my-house">
          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between start">
            <div fxFlex fxFlex.gt-xs="70">
              <div ngClass.gt-xs="p-v-40" ngClass.xs="p-h-22 p-v-30">
                <div>
                  <div ngClass.gt-xs="mat-subtitle-2 m-b-10"
                       ngClass.xs="mat-body-1 ff-sc-6 m-b-5">
                    부동산 등기변경알림
                  </div>
                  <div ngClass.gt-xs="mat-headline-6 m-b-10"
                       ngClass.xs="mat-subtitle-1 m-b-10">
                    한번의 알림신청으로 !
                  </div>
                  <div ngClass.gt-xs="mat-subtitle-1 lh-1-6 m-b-25"
                       ngClass.xs="mat-body-1 f-600 ff-sc-5 m-b-30 lh-1-5">
                    위험 등기정보를 <span ngClass.gt-xs="mat-subtitle-3 f-600 bg-neon-mint c-white" ngClass.xs="f-700 bg-neon-mint c-white">자동으로 간편하게 확인</span>
                    하세요.
                  </div>
                  <div fxLayout="column" class="p-b-20" fxHide fxShow.gt-xs>
                    <div fxLayout="row wrap" fxLayoutAlign="start center"
                         fxLayoutGap="5px">
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">부동산 담보</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">부동산 계약</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">가맹점 담보</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">임차인</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">채권자</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">소유자</div>
                    </div>
                  </div>
                  <div fxShow fxHide.gt-xs fxLayoutAlign="center" class="m-t-m10">
                    <img alt="부동산권리분석" class="w-90"
                         src="assets/img/service/etc/main-visual-alarm-mobile.png">
                  </div>

                  <div fxHide fxLayout="column">
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">부동산 담보</div>
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">부동산 계약</div>
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">가맹점 담보</div>
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">임차인</div>
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">채권자</div>
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">소유자</div>
                    </div>
                  </div>
                  <div fxHide fxShow.gt-xs ngClass.gt-xs="mat-body-1 cursor p-l-5"><u>&nbsp;자세히 보기&nbsp;</u></div>
                </div>
              </div>
            </div>
            <div fxHide fxShow.gt-xs fxFlex.gt-xs="30" fxLayoutAlign.gt-xs="center start">
              <img alt="부동산권리분석" class="img-size-260"
                   src="assets/img/service/etc/main-visual-alarm.png">

              <!--              <img alt="부동산권리분석" class="img-size-260" src="assets/img/character/businessman-enjoys-message-mint.svg">-->
            </div>
          </div>
        </swiper-slide>

        <swiper-slide fxLayout="column" ngClass.gt-xs="p-l-60" routerLink="/info/open-house">
          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between start">
            <div fxFlex fxFlex.gt-xs="70">
              <div ngClass.gt-xs="p-v-40" ngClass.xs="p-h-22 p-v-30">
                <div>
                  <div ngClass.gt-xs="mat-subtitle-2 m-b-10"
                       ngClass.xs="mat-body-1 ff-sc-6 m-b-5">
                    온투업 오픈부동산
                  </div>
                  <div ngClass.gt-xs="mat-headline-6 m-b-10"
                       ngClass.xs="mat-subtitle-1 m-b-10">
                    온투업 사업자와 투자자 모두!
                  </div>
                  <div ngClass.gt-xs="mat-subtitle-1 lh-1-6 m-b-25"
                       ngClass.xs="mat-body-1 f-600 ff-sc-5 m-b-30 lh-1-5">
                    부동산 <span ngClass.gt-xs="mat-subtitle-3 f-600 bg-neon-mint c-white" ngClass.xs="f-700 bg-neon-mint c-white">담보 현황과 변경 등기</span>
                    를 빠르게 확인 하세요.
                  </div>
                  <div fxLayout="column" class="p-b-20" fxHide fxShow.gt-xs>
                    <div fxLayout="row wrap" fxLayoutAlign="start center"
                         fxLayoutGap="5px">
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">온투업투자</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">온투업투자</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">대출부동산</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">부동산담보</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">담보관리</div>
                    </div>
                  </div>
                  <div fxShow fxHide.gt-xs fxLayoutAlign="start end" class="m-t-m20 m-b-m20">
                    <img alt="권리분석" class="img-size-150" src="assets/img/character/listinformation.svg">
                  </div>

                  <div fxHide fxLayout="column">
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">온투업투자</div>
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">온투업투자</div>
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">대출부동산</div>
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">부동산담보</div>
                      <div class="gray-bg-chip-xs bg-mint-light c-black m-b-7">담보관리</div>
                    </div>
                  </div>
                  <div fxHide fxShow.gt-xs ngClass.gt-xs="mat-body-1 cursor p-l-5"><u>&nbsp;자세히 보기&nbsp;</u></div>
                </div>
              </div>
            </div>
            <div fxHide fxShow.gt-xs fxFlex.gt-xs="30" fxLayoutAlign.gt-xs="center start">
              <img alt="부동산권리분석" class="img-size-220" src="assets/img/character/listinformation.svg">
            </div>
          </div>
        </swiper-slide>

      </app-swiper>
    </div>
  </section>
</div>
<!--main visual end-->

<div fxHide fxShow.gt-xs>
  <section class="wide-section banner-xs p-v-5 m-b-50">
    <div fxLayout.gt-xs="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutGap="20px"
         class="bg-gray-light c-black p-v-40 p-h-40 m-b-10 brd-15">
      <div fxFlex fxFlex.gt-xs="60" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="start center">
          <div class="p-t-7">
            <img alt="권리분석" class="img-size-80" src="assets/img/service/etc/coupon-free-accent.png">
          </div>
          <div>
            <div fxLayout.xs="row" fxLayoutGap.xs="10px" fxLayoutAlign.xs="start center">
              <div ngClass.gt-xs="mat-body-1 m-b-3 ff-sc-6">권리분석 베타 서비스</div>
              <div ngClass.gt-xs="mat-subtitle-1 ff-sc-6 p-b-5">
                체크팀이 부동산 권리분석 서비스를 시작합니다!
              </div>

            </div>
            <div ngClass.gt-xs="mat-body-2 f-600 m-b-0">
              <div>이용신청 후 발급된 쿠폰으로 이용하실 수 있습니다. (무료 분석 100건)</div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex fxFlex.gt-xs="40" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="end center">
        <div class="mat-body-1 cursor"  routerLink="/analysis/info">
          내용 보기
        </div>

        <a href="https://forms.gle/wCSsPUSMbyMATCai7" target="_blank">
          <div class="p-30 bg-accent c-white text-center brd-50 mat-subtitle-3 shadow-box hover-primary cursor">
            무료 분석 쿠폰 받기
          </div>
        </a>
      </div>
    </div>
  </section>
</div>


<!--퀵 메뉴-->
<section class="wide-section banner ">
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px"
       ngClass.gt-xs="p-b-20 p-t-10" ngClass.xs="p-t-35 p-b-15 p-h-20 m-t-m120">
    <div fxFlex="100" fxFlex.gt-xs="25">
      <div class="brd-15 cursor">
        @if (isLogin) {
          <div fxLayout="column" ngClass.gt-xs="bg-white-blur c-dark shadow-box-xs p-t-10 p-b-15 brd-15"
               ngClass.xs="bg-gray-fa shadow-box p-l-20 p-r-10 p-v-10 m-b-25 brd-15">
            <div ngClass.gt-xs="p-h-20">
              <div fxHide fxShow.gt-xs class="p-l-5">
                <span class="material-symbols-outlined c-accent f-60">person_pin_circle</span>
              </div>
              <div class="p-b-5" fxHide>
                <span class="material-symbols-outlined c-accent f-55 ">person_pin_circle</span>
              </div>
            </div>
            <div ngClass.gt-xs="p-l-30 p-r-20" ngClass.xs="p-h-10 p-t-10">
              <div>
                <div ngClass.gt-xs="mat-subtitle-2 m-b-3 ff-sc-6 p-t-7"
                     ngClass.xs="mat-subtitle-3 p-t-5 m-b-3">
                  <span>마이 부동산</span>
                </div>
                <div ngClass.gt-xs="mat-body-2 f-600 p-b-3" ngClass.xs="mat-body-1 f-600 p-b-10">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div routerLink="/analysis/list">
                      권리 분석
                      <span class="p-l-2 f-600 ff-sc-6">{{ counts.analysis | number }}</span>
                      <span class="p-l-3 f-12">({{ counts.analysisPending | number }})</span>
                      <span fxShow fxHide.gt-xs class="material-symbols-outlined f-24 c-gray">play_arrow</span>
                    </div>
                    <div fxHide fxShow.gt-xs class="f-10 c-gray">ㅣ</div>
                    <div routerLink="/house/list">
                      등기 변경 알림
                      <span class="p-l-2 f-600 ff-sc-6">{{ counts.house | number }}</span>
                      <span fxShow fxHide.gt-xs class="material-symbols-outlined f-24 c-gray">play_arrow</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        } @else {
          <div fxLayout="column" routerLink="/analysis/list"  ngClass.gt-xs="bg-white-blur c-dark shadow-box-xs p-t-10 p-b-15 brd-15"
               ngClass.xs="bg-gray-fa shadow-box p-h-20 p-v-10 m-b-25 brd-15 ">
            <div ngClass.gt-xs="p-h-20">
              <div fxHide fxShow.gt-xs class="p-l-5">
                <span class="material-symbols-outlined c-accent f-75 m-b-m13">key</span>
              </div>
              <div class="p-b-5" fxHide.gt-xs fxShow>
                <span class="material-symbols-outlined c-accent f-70 m-b-m20">key</span>
              </div>
            </div>
            <div ngClass.gt-xs="p-h-30 p-t-8" ngClass.xs="p-h-10">
              <div>
                <div ngClass.gt-xs="mat-subtitle-2 m-b-3 ff-sc-6"
                     ngClass.xs="mat-subtitle-3 p-t-5 m-b-3">
                  <span>마이 부동산</span>
                </div>
                <div ngClass.gt-xs="mat-body-2 f-600 p-b-3" ngClass.xs="mat-body-1 f-600 p-b-10">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="p-r-20">
                      로그인 후 확인할 수 있습니다.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>

    <div fxFlex="100" fxFlex.gt-xs="25">
      <div class="brd-15 cursor"
           ngClass.gt-xs="bg-accent-blur bg-gr-accent-mint shadow-box hover-shadow p-t-10 p-b-20"
           ngClass.xs="bg-gr-accent-mint p-h-20 p-v-10 m-b-15"
           routerLink="/analysis">
        <div fxLayout="column">
          <div ngClass.gt-xs="p-h-10">
            <div fxHide fxShow.gt-xs fxLayoutAlign="end">
              <span class="material-symbols-outlined c-white f-65 icon-tran-scalex">mindfulness</span>
            </div>
            <div fxShow fxHide.gt-xs fxLayoutAlign="start" class="p-b-5 p-l-3">
              <span class="material-symbols-outlined c-white f-55">mindfulness</span>
            </div>
          </div>
          <div ngClass.gt-xs="m-t-5 p-l-30 p-r-10" ngClass.xs="m-t-m5">
            <div ngClass.xs="p-b-10 p-h-10">
              <div ngClass.gt-xs="mat-subtitle-2 m-b-3 ff-sc-6"
                   ngClass.xs="mat-subtitle-3 p-t-5">
                부동산 권리분석
              </div>
              <div ngClass.gt-xs="mat-body-2" ngClass.xs="mat-body-2 f-600">
                담보분석 &middot; 전세보증금 진단 &middot; 경매배당표
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="100" fxFlex.gt-xs="25">
      <div class="brd-15 cursor"
           ngClass.gt-xs="bg-gray-blur c-dark shadow-box hover-shadow p-t-10 p-b-20"
           ngClass.xs="bg-gray-light c-black p-h-20 p-v-10 m-b-15"
           [queryParams]="{type: 'MY_HOUSE'}" routerLink="/house/register">
        <div fxLayout="column">
          <div ngClass.gt-xs="p-h-10">
            <div fxHide fxShow.gt-xs fxLayoutAlign="end">
              <span class="material-symbols-outlined c-mint f-65">add_location</span>
            </div>
            <div fxShow fxHide.gt-xs fxLayoutAlign="start" class="f-400 m-l-m2 p-b-5">
              <span class="material-symbols-outlined c-mint f-58">add_location</span>
            </div>
            <div fxHide>
              <lord-icon colors="primary:#f6f7f7,secondary:#0fa564,tertiary:#0fa564,quaternary:#f6f7f7" src="https://cdn.lordicon.com/jqlpbeta.json" target="#myreal" trigger="hover" style="width: 150px; height: 150px;"></lord-icon>
            </div>
          </div>

          <div ngClass.gt-xs="m-t-5 p-h-30" ngClass.xs="m-t-m10">
            <div ngClass.xs="p-b-10 p-h-10">
              <div ngClass.gt-xs="mat-subtitle-2 m-b-3 ff-sc-6"
                   ngClass.xs="mat-subtitle-3 p-t-5">
                부동산 등기변경 알림 신청
              </div>
              <div ngClass.gt-xs="mat-body-2" ngClass.xs="mat-body-2 f-600">
                등기 변경알림 &middot; 부동산등본 열람
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="100" fxFlex.gt-xs="25">
      <div class="brd-15 cursor"
           ngClass.gt-xs="bg-gray-blur c-dark shadow-box hover-shadow p-t-10 p-b-20"
           ngClass.xs="bg-gray-light c-dark p-h-20 p-v-10 m-b-15" routerLink="/house/open">
        <div fxLayout="column">
          <div ngClass.gt-xs="p-h-10">
            <div fxHide fxShow.gt-xs fxLayoutAlign="end">
              <span class="material-symbols-outlined c-accent f-400 f-65">circle_notifications</span>
            </div>
            <div fxShow fxHide.gt-xs fxLayoutAlign="start" class="p-b-5">
              <span class=" material-symbols-outlined c-accent f-400 f-55">circle_notifications</span>
            </div>
          </div>

          <div ngClass.gt-xs="m-t-5 p-l-30">
            <div ngClass.xs="p-b-10 p-h-10">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px" ngClass.gt-xs="m-b-3">
                <div ngClass.gt-xs="mat-subtitle-2 ff-sc-6"
                     ngClass.xs="mat-subtitle-3 c-dark">
                  오픈 부동산 알림신청
                </div>
                <div class="badge-kr-xs bg-accent p-h-5 p-b-5 m-b-4">new</div>

              </div>

              <div ngClass.gt-xs="mat-body-2" ngClass.xs="mat-body-2 f-600">
                온투업 투자부동산 담보현황 자동안내
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxShow fxHide.gt-xs fxFlex="100">
      <div class="brd-15 cursor bg-gray-light p-h-20 p-v-10 m-b-15" routerLink="/calculator">
        <div fxLayout="column">
          <div fxShow fxHide.gt-xs fxLayoutAlign="start" class="p-b-5 m-l-m5">
            <span class="material-symbols-outlined c-green f-500 f-65">calculate</span>
          </div>
          <div class="m-t-m10">
            <div class="p-b-10 p-h-8">
              <div class="mat-subtitle-3 p-t-5">부동산 계산기</div>
              <div ngClass.gt-xs="mat-body-2" ngClass.xs="mat-body-2 f-600">
                증여세 &middot; 양도세 &middot; 임대소득세 &middot; LTV &middot; DTI 등
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<!--이벤트-->
<!--todo : 2025.01.02 kdh 베타오픈 기간동안 가림-->
<div fxHide ngClass.gt-xs="p-b-30 m-t-30" ngClass.xs="p-h-15 m-t-m10">
  <section class="wide-section banner-xs">
    <div fxShow.gt-xs fxHide class="p-l-20 m-b-3">
      <span class="badge-kr-md bg-mint p-h-30 p-b-20 p-t-10 f-600">체크팀 이벤트</span>
    </div>
    <div fxShow fxHide.gt-xs class="m-b-10">
      <span class="badge-kr-md bg-gr-mint-green p-h-20 f-600">체크팀 이벤트</span>
    </div>
    <div fxLayout="row" fxLayoutGap="20px" class="of-auto">

      <div fxFlex.gt-xs fxFlex.xs="80vw" fxLayout="column"
           fxLayoutGap="10px" ngClass.gt-xs="bg-gray-light c-black p-v-40 brd-15 cursor"
           ngClass.xs="bg-primary-light c-black p-t-10 p-b-30 brd-15"
           routerLink="/info/events">
        <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap.gt-xs="10px">
          <div>
            <div fxLayout.xs="row" fxLayoutGap.xs="10px" fxLayoutAlign.xs="start center" ngClass.xs="p-b-10 p-l-20">
              <div fxShow fxHide.gt-xs>
                <img alt="권리분석" class="img-size-70" src="assets/img/service/etc/coupon-free-1report.png">
              </div>
              <div ngClass.gt-xs="mat-subtitle-3 m-b-3 ff-sc-6 p-b-10 p-l-30"
                   ngClass.xs="mat-subtitle-3 p-t-5 m-b-0 ff-sc-7">부동산 권리분석과
                <div></div>
                위험 권리를 무료로 확인하세요.
              </div>
            </div>

            <div ngClass.gt-xs="mat-body-1 f-600 m-b-0 p-l-30" ngClass.xs="p-h-20">
              <div>회원가입 즉시 무료 쿠폰(부동산등본 열람 포함)이 발급됩니다.</div>
            </div>
          </div>
          <div fxHide fxShow.gt-xs class="p-r-20">
            <img alt="권리분석" class="img-size-90" src="assets/img/service/etc/coupon-free-1report.png">
          </div>
        </div>
      </div>

      <div fxFlex.gt-xs fxFlex.xs="80vw" fxLayout="column" fxLayoutGap="10px"
           ngClass.gt-xs="bg-gray-light c-black p-v-40 brd-15 cursor"
           ngClass.xs="bg-primary-light c-black p-t-10 p-b-30 brd-15"
           routerLink="/info/events">
        <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap.gt-xs="10px">
          <div>
            <div fxLayout.xs="row" fxLayoutGap.xs="10px"
                 ngClass.xs="p-t-10 p-b-10 p-l-20">
              <div fxShow fxHide.gt-xs>
                <img alt="권리분석" class="img-size-60" src="assets/img/service/etc/cup-startbucks.svg">
              </div>
              <div ngClass.gt-xs="mat-subtitle-3 m-b-3 ff-sc-6 p-b-10 p-l-30"
                   ngClass.xs="mat-subtitle-3 p-t-5 m-b-0 ff-sc-7">권리분석 정보를 공유하고<br>
                스타벅스 커피 쿠폰을 받으세요.
              </div>
            </div>

            <div ngClass.gt-xs="mat-body-1 f-600 m-b-0 p-l-30" ngClass.xs="p-h-20">
              <div>권리분석 이용 후 정보를 공유하는 모든 회원님께 스타벅스 쿠폰을 드립니다.</div>
            </div>
          </div>
          <div fxHide fxShow.gt-xs class="p-r-20">
            <img alt="권리분석" class="img-size-90" src="assets/img/service/etc/cup-startbucks.svg">
          </div>
        </div>
      </div>


      <!--      <div fxFlex.gt-xs fxFlex.xs="80vw" fxLayout="column"-->
      <!--           fxLayoutGap="10px" ngClass.gt-xs="bg-gray-light p-v-40 brd-15 cursor"-->
      <!--           ngClass.xs="bg-mint-light c-dark p-t-10 p-b-30 brd-15"-->
      <!--           routerLink="/info/events">-->
      <!--        <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap.gt-xs="10px">-->
      <!--          <div>-->
      <!--            <div fxLayout.xs="row" fxLayoutGap.xs="10px" fxLayoutAlign.xs="start center" ngClass.xs="p-b-10 p-l-20">-->
      <!--              <div fxShow fxHide.gt-xs>-->
      <!--                <img alt="권리분석" class="img-size-70" src="assets/img/service/etc/coupon-free-1m.png">-->
      <!--              </div>-->
      <!--              <div ngClass.gt-xs="mat-subtitle-3 m-b-3 ff-sc-6 p-b-10 p-l-30"-->
      <!--                   ngClass.xs="mat-subtitle-3 c-black p-t-5 m-b-0 ff-sc-7">무료 등기변경알림을-->
      <!--                <div></div>-->
      <!--                이용해보세요-->
      <!--              </div>-->
      <!--            </div>-->

      <!--            <div ngClass.gt-xs="mat-body-1 f-600 m-b-0 p-l-30" ngClass.xs="p-h-20 c-black">-->
      <!--              <div>가입 즉시 등기변경알림과 부동산등본열람 쿠폰이 지급됩니다.</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div fxHide fxShow.gt-xs class="p-r-20">-->
      <!--            <img alt="권리분석" class="img-size-90" src="assets/img/service/etc/coupon-free-1m.png">-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </section>
</div>

<div class="bg-gray-light">
  <section class="wide-section banner" fxLayout="column" fxLayout.gt-xs="row"
           fxLayoutAlign.gt-xs="space-around center" fxLayoutGap="30px" fxLayoutGap.gt-xs="40px"
           ngClass.gt-xs="m-b-15" ngClass.xs="bg-gray-light">

    <div ngClass.gt-xs="p-v-20 p-l-20" ngClass.xs="p-h-15">
      <div fxFlex.gt-xs="51">
        <div ngClass.gt-xs="p-t-40" ngClass.xs="p-h-10 p-t-30 p-b-40">
          <div ngClass.gt-xs="mat-subtitle-1 f-600 ff-sc-6 p-t-20 m-b-7"
               ngClass.xs="mat-subtitle-2 f-600 ff-sc-6 p-t-10 m-b-5">
            온투업 부동산 투자상품의
          </div>
          <div class="ff-sc-5 lh-1-7" ngClass.gt-xs="mat-headline-6 m-b-15"
               ngClass.xs="mat-subtitle-1 f-600 ff-sc-6 p-b-10">
            담보정보를 무료로 확인하세요!
          </div>
          <div ngClass.gt-xs="ff-sc-4 f-600 mat-body-1 m-b-5" ngClass.xs="ff-sc-4 f-600 mat-body-2 m-b-5">
            투자자는 알림신청만으로 투자부동산의 담보현황을 확인할 수 있습니다.
          </div>

          <div class="p-t-10" fxHide.gt-xs fxShow ngClass.xs="p-b-50" routerLink="/info/open-house">
            <div class="cursor" fxLayout="row wrap">
              <div *ngFor="let logo of partnerLogos" fxFlex="25" fxLayoutAlign="start center">
                <div>
                  <img [src]="logo" alt="파트너" class="img-size-95p">
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" ngClass.gt-xs="p-t-40 w-70">
            <button class="bg-gray hover-dark p-v-25 f-600" ngClass.gt-xs="p-h-40" ngClass.xs="p-h-0" fxFlex
                    mat-raised-button
                    routerLink="/info/open-house">
              서비스 소개
            </button>
            <button class="bg-mint-darker hover-dark p-v-25 f-600 l-h-0" fxFlex mat-raised-button
                    ngClass.gt-xs="p-h-40"
                    ngClass.xs="p-h-0" routerLink="/house/open">
              오픈부동산 보기
            </button>
          </div>
        </div>
      </div>

      <div fxFlex.gt-xs="44" fxHide fxShow.gt-xs>
        <div ngClass.gt-xs="p-v-20 text-center">
          <div ngClass.xs="p-b-50" routerLink="/info/open-house">
            <div class="cursor" fxLayout="row wrap">
              <div *ngFor="let logo of partnerLogos" fxFlex="20" fxLayoutAlign="start center">
                <div class="p-2">
                  <img [src]="logo" alt="파트너" class="img-size-100p">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="showStoreLink">
  <section class="wide-section banner-xs">
    <div ngClass.xs="p-20">
      <div class="p-b-20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.gt-xs="40px">
        <img (click)="openPlayStore()" alt="Play Store Link" class="cursor img-size-50"
             src="assets/img/store/GooglePlay.png">
        <img (click)="openAppStore()" alt="AppStore Link" class="cursor img-size-50"
             src="assets/img/store/AppStore.png">
      </div>
      <div class="mat-body-2 text-center">
        체크팀 앱을 다운받으시면 등기변경 안내를 앱(푸시) 알림으로도 확인할 수 있습니다.
      </div>
    </div>
  </section>
</div>

<app-footer></app-footer>
