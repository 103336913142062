<app-header [login]="isLogin" [mobile]="mobile"/>

<div ngClass.gt-xs="p-t-70" ngClass.xs="p-v-60">
  <ngx-pull-to-refresh
    (refresh)="myRefreshEvent($event)"
    [distanceForRefresh]="200"
    [isEnable]="mobile && enableRefresh"
    [spinnerSize]="50"
    [targetElement]="targetElement"
    spinnerColor="#2db7b7">

    <router-outlet></router-outlet>
  </ngx-pull-to-refresh>
</div>

@if (mobile) {
  <app-bottom [currentUrl]="currentUrl"/>
}

<ngx-spinner [fullScreen]="true" class="img-size-100 text-center"
             template="<img src='assets/img/gif/loading-progress-mint.svg'/>">
  <p class="p-t-10 lh-1-7 f-15 f-600" style="color: white">
    정보를 확인하고 있습니다.<br>
    <span class="f-13 600">잠시만 기다려주세요 !</span>
  </p>
</ngx-spinner>

<app-scroll/>
