import {Component, Inject} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Router, RouterModule} from '@angular/router';
import {UserService} from '../../../domain/user/service/user.service';
import {SessionStorageService} from '../../../_service/session-storage.service';
import {NativeService} from '../../../_service/native.service';
import {CommonModule} from "@angular/common";
import {FlexLayoutModule} from "@ngbracket/ngx-layout";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-mobile-footer-sheet',
  templateUrl: './mobile-footer-sheet.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatBottomSheetModule,
    MatListModule,
    MatIconModule
  ]
})
export class MobileFooterSheetComponent {
  links: any[] = [];

  constructor(private readonly log: NGXLogger,
              @Inject(MAT_BOTTOM_SHEET_DATA) private readonly data: any,
              private readonly nativeService: NativeService,
              private readonly ref: MatBottomSheetRef,
              private readonly router: Router,
              private readonly userService: UserService,
              private readonly sessionStorage: SessionStorageService) {
    this.links = this.data.links;
  }

  clickLink(link: any) {
    if (link.logout) {
      this.userService.logout();
      this.sessionStorage.clear();
      this.nativeService.forgetMe();
    } else {
      this.router.navigate([link.path], {queryParams: link.queryParams})
      this.ref.dismiss(link.type)
    }
    this.ref.dismiss();
  }
}
