<app-main-notice />


<div ngClass.gt-xs="bg-04" ngClass.xs="bg-gray-light">
  <!--서비스 소개 시작-->
  <div ngClass.gt-xs="bg-white">
    <section class="main-visual-section min-h-10 p-0" ngClass.xs="bg-white">
      <div [ngClass.gt-xs]="isLogin ? 'p-t-80 p-b-40' : 'p-t-80 p-b-147'">
        <app-swiper paginationType="bullets" swiperId="bullets">
          @if (isLogin) {
            <swiper-slide fxLayout="column">
              <div ngClass="narrow-section min-h-10 p-0" fxLayout.gt-xs="row" fxLayout.xs="column"
                   fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.xs="20px" ngClass.xs="p-h-25 p-v-30"
                   routerLink="/analysis/info">
                <div fxFlex.xs fxFlex.gt-xs="60">
                  <div>
                    <div ngClass.gt-xs="mat-subtitle-2 p-b-8" ngClass.xs="mat-body-1 m-b-5">
                      # 부동산 권리분석
                    </div>
                    <div ngClass.gt-xs="mat-headline-2 p-b-26" ngClass.xs="f-22 f-w-900">
                      부동산 권리 분석,<br/>어렵고 걱정되시죠?
                    </div>
                    <div ngClass.gt-xs="mat-headline-6 f-w-460 p-b-36" ngClass.xs="mat-subtitle-3">
                      체크팀으로 내일의 위험까지 분석하세요.
                    </div>
                    <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.gt-xs="2px"
                         fxLayoutGap.xs="2px" ngClass.gt-xs="p-b-48" routerLink="/analysis/info">
                      <div ngClass.gt-xs="mat-subtitle-3 cursor p-r-5 hover-line">
                        자세히 보기
                      </div>
                      <span class="material-symbols-outlined f-24 f-w-600">east</span>
                    </div>
                  </div>
                </div>
                <div fxFlex fxFlex.gt-xs="40" ngClass.gt-xs="m-t-m110">
                  <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center" fxLayoutAlign.xs="start start"
                       fxLayoutGap="5px" ngClass.xs="p-b-35">
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      담보대출 분석
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      전세보증금 진단
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      경매 시뮬레이션
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      자산가치 분석
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      매매계약 진단
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      가맹점 담보분석
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide fxLayout="column">
              <div ngClass="narrow-section min-h-10 p-0" fxLayout.gt-xs="row" fxLayout.xs="column"
                   fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.xs="20px" ngClass.xs="p-h-25 p-v-30"
                   routerLink="/info/my-house">
                <div fxFlex.xs fxFlex.gt-xs="65">
                  <div>
                    <div ngClass.gt-xs="mat-subtitle-2 p-b-8" ngClass.xs="mat-body-1 m-b-5">
                      # 부동산 등기변경알림
                    </div>
                    <div ngClass.gt-xs="mat-headline-2 p-b-40" ngClass.xs="f-22 f-w-900">
                      한번의 신청으로,<br/>등기변경 정보를 빠르게 확인!
                    </div>
                    <div ngClass.gt-xs="mat-headline-6 f-w-460 p-b-36" ngClass.xs="mat-subtitle-3">
                      위험 등기변경 정보를 간편하게 확인 하세요.
                    </div>
                    <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="start center" ngClass.gt-xs="p-b-48"
                         routerLink="/info/my-house">
                      <div ngClass.gt-xs="mat-subtitle-3 cursor p-r-5 hover-line">
                        자세히 보기
                      </div>
                      <span class="material-symbols-outlined f-24 f-w-600">east</span>
                    </div>
                  </div>
                </div>
                <div fxFlex fxFlex.gt-xs="35" ngClass.gt-xs="m-t-m110">
                  <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center" fxLayoutAlign.xs="start start"
                       fxLayoutGap="5px" ngClass.xs="p-b-35">
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      부동산 담보
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      부동산 계약
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      가맹점 담보
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      임차인
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      채권자
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide fxLayout="column">
              <div ngClass="narrow-section min-h-10 p-0" fxLayout.gt-xs="row" fxLayout.xs="column"
                   fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.xs="20px" ngClass.xs="p-h-25 p-v-30"
                   routerLink="/info/open-house">
                <div fxFlex.xs fxFlex.gt-xs="60">
                  <div>
                    <div ngClass.gt-xs="mat-subtitle-2 p-b-8" ngClass.xs="mat-body-1 m-b-5">
                      # 온투업 오픈부동산
                    </div>
                    <div ngClass.gt-xs="mat-headline-2 p-b-40" ngClass.xs="f-22 f-w-900">
                      온투업 사업자와 투자자,<br/>등기변경 자동 확인!
                    </div>
                    <div ngClass.gt-xs="mat-headline-6 f-w-460 p-b-36" ngClass.xs="mat-subtitle-3">
                      담보현황과 변경등기를 빠르게 확인하세요.
                    </div>
                    <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="start center" ngClass.gt-xs="p-b-48"
                         routerLink="/info/open-house">
                      <div ngClass.gt-xs="mat-subtitle-3 cursor p-r-5 hover-line">
                        자세히 보기
                      </div>
                      <span class="material-symbols-outlined f-24 f-w-600">east</span>
                    </div>
                  </div>
                </div>
                <div fxFlex fxFlex.gt-xs="40" ngClass.gt-xs="m-t-m110">
                  <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center" fxLayoutAlign.xs="start start"
                       fxLayoutGap="5px" ngClass.xs="p-b-35">
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      온투업 투자
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      온투업 사업자
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      대출 부동산
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      투자 부동산
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      담보 현황 안내
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          }
          @else {
            <!--          로그아웃 상태의 서비스 소개 영역 시작-->
            <swiper-slide fxLayout="column">
              <div ngClass="narrow-section min-h-10" fxLayout.gt-xs="row" fxLayout.xs="column"
                   fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.xs="20px" ngClass.xs="p-h-22 p-v-30"
                   routerLink="/analysis/info">
                <div fxFlex.xs fxFlex.gt-xs="60">
                  <div>
                    <div ngClass.gt-xs="mat-subtitle-2 p-b-8" ngClass.xs="mat-body-1 m-b-5">
                      # 부동산 권리분석
                    </div>
                    <div ngClass.gt-xs="mat-headline-2 p-b-40" ngClass.xs="f-22 f-w-900">
                      부동산 권리 분석,<br/>어렵고 걱정되시죠?
                    </div>
                    <div ngClass.gt-xs="mat-headline-6 f-w-460 p-b-36" ngClass.xs="mat-subtitle-3">
                      체크팀으로 내일의 위험까지 분석하세요.
                    </div>
                    <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="start center" ngClass.gt-xs="p-b-48"
                         routerLink="/analysis/info">
                      <div ngClass.gt-xs="mat-subtitle-3 cursor p-r-5 hover-line">
                        자세히 보기
                      </div>
                      <span class="material-symbols-outlined f-24 f-w-600">east</span>
                    </div>
                  </div>
                </div>
                <div fxFlex fxFlex.gt-xs="40" ngClass.gt-xs="m-t-m110">
                  <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center" fxLayoutAlign.xs="start start"
                       fxLayoutGap="5px" ngClass.xs="p-b-35">
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      담보대출 분석
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      전세보증금 진단
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      경매 시뮬레이션
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      자산가치 분석
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      매매계약 진단
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      가맹점 담보분석
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide fxLayout="column">
              <div ngClass="narrow-section min-h-10 p-0" fxLayout.gt-xs="row" fxLayout.xs="column"
                   fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.xs="20px" ngClass.xs="p-h-22 p-v-30"
                   routerLink="/analysis/info">
                <div fxFlex.xs fxFlex.gt-xs="60">
                  <div>
                    <div ngClass.gt-xs="mat-subtitle-2 p-b-8" ngClass.xs="mat-body-1 m-b-5">
                      # 부동산 권리분석
                    </div>
                    <div ngClass.gt-xs="mat-headline-2 p-b-40" ngClass.xs="f-22 f-w-900">
                      우리집,<br/>전세보증금 분석!
                    </div>
                    <div ngClass.gt-xs="mat-headline-6 f-w-460 p-b-36" ngClass.xs="mat-subtitle-3">
                      안전성과 위험 권리를 확인하세요.
                    </div>
                    <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="start center" ngClass.gt-xs="p-b-48"
                         routerLink="/analysis/info">
                      <div ngClass.gt-xs="mat-subtitle-3 cursor p-r-5 hover-line">
                        자세히 보기
                      </div>
                      <span class="material-symbols-outlined f-24 f-w-600">east</span>
                    </div>
                  </div>
                </div>
                <div fxFlex fxFlex.gt-xs="40" ngClass.gt-xs="m-t-m110">
                  <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center" fxLayoutAlign.xs="start start"
                       fxLayoutGap="5px" ngClass.xs="p-b-35">
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      전세보증금 안전성
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      법원 예상배당표
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      위험 권리 확인
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      전세 적정시세
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      체납세금 확인
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide fxLayout="column">
              <div ngClass="narrow-section min-h-10 p-0" fxLayout.gt-xs="row" fxLayout.xs="column"
                   fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.xs="10px" ngClass.xs="p-h-22 p-v-30"
                   routerLink="/analysis/info">
                <div fxFlex.xs fxFlex.gt-xs="60">
                  <div>
                    <div ngClass.gt-xs="mat-subtitle-2 p-b-8" ngClass.xs="mat-body-1 m-b-5">
                      # 부동산 권리분석
                    </div>
                    <div ngClass.gt-xs="mat-headline-2 p-b-40" ngClass.xs="f-22 f-w-900">
                      등본에서 배당표 분석까지<br/>55초!
                    </div>
                    <div ngClass.gt-xs="mat-headline-6 f-w-460 p-b-36" ngClass.xs="mat-subtitle-3">
                      권리분석을 빠르고 효율적으로 자동화하세요.
                    </div>
                    <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="start center" ngClass.gt-xs="p-b-48"
                         routerLink="/analysis/info">
                      <div ngClass.gt-xs="mat-subtitle-3 cursor p-r-5 hover-line">
                        자세히 보기
                      </div>
                      <span class="material-symbols-outlined f-24 f-w-600">east</span>
                    </div>
                  </div>
                </div>
                <div fxFlex fxFlex.gt-xs="40" ngClass.gt-xs="m-t-m110">
                  <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center" fxLayoutAlign.xs="start start"
                       fxLayoutGap="5px" ngClass.xs="p-b-35">
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      권리분석 보고서
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      법원 예상 배당표
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      보고서 다운로드
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      부동산등본 자동발급
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      시세 확인
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide fxLayout="column">
              <div ngClass="narrow-section min-h-10 p-0" fxLayout.gt-xs="row" fxLayout.xs="column"
                   fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.xs="20px" ngClass.xs="p-h-22 p-v-30"
                   routerLink="/info/my-house">
                <div fxFlex.xs fxFlex.gt-xs="65">
                  <div>
                    <div ngClass.gt-xs="mat-subtitle-2 p-b-8" ngClass.xs="mat-body-1 m-b-5">
                      # 부동산 등기변경알림
                    </div>
                    <div ngClass.gt-xs="mat-headline-2 p-b-40" ngClass.xs="f-22 f-w-900">
                      한번의 신청으로,<br/>변경 정보를 빠르게 확인!
                    </div>
                    <div ngClass.gt-xs="mat-headline-6 f-w-460 p-b-36" ngClass.xs="mat-subtitle-3">
                      위험 등기변경 정보를 간편하게 하세요.
                    </div>
                    <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="start center" ngClass.gt-xs="p-b-48"
                         routerLink="/info/my-house">
                      <div ngClass.gt-xs="mat-subtitle-3 cursor p-r-5 hover-line">
                        자세히 보기
                      </div>
                      <span class="material-symbols-outlined f-24 f-w-600">east</span>
                    </div>
                  </div>
                </div>
                <div fxFlex fxFlex.gt-xs="35" ngClass.gt-xs="m-t-m110">
                  <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center" fxLayoutAlign.xs="start start"
                       fxLayoutGap="5px" ngClass.xs="p-b-35">
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      부동산 담보
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      부동산 계약
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      가맹점 담보
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      임차인
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      채권자
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide fxLayout="column">
              <div ngClass="narrow-section min-h-10 p-0" fxLayout.gt-xs="row" fxLayout.xs="column"
                   fxLayoutAlign.gt-xs="space-between center" fxLayoutGap.xs="20px" ngClass.xs="p-h-22 p-v-30"
                   routerLink="/info/open-house">
                <div fxFlex.xs fxFlex.gt-xs="60">
                  <div>
                    <div ngClass.gt-xs="mat-subtitle-2 p-b-8" ngClass.xs="mat-body-1 m-b-5">
                      # 온투업 오픈부동산
                    </div>
                    <div ngClass.gt-xs="mat-headline-2 p-b-40" ngClass.xs="f-22 f-w-900">
                      온투업 사업자와 투자자,<br/>등기변경 자동 확인!
                    </div>
                    <div ngClass.gt-xs="mat-headline-6 f-w-460 p-b-36" ngClass.xs="mat-subtitle-3">
                      담보현황과 변경등기를 빠르게 확인하세요.
                    </div>
                    <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="start center" ngClass.gt-xs="p-b-48"
                         routerLink="/info/open-house">
                      <div ngClass.gt-xs="mat-subtitle-3 cursor p-r-5 hover-line">
                        자세히 보기
                      </div>
                      <span class="material-symbols-outlined f-24 f-w-600">east</span>
                    </div>
                  </div>
                </div>
                <div fxFlex fxFlex.gt-xs="40" ngClass.gt-xs="m-t-m110">
                  <div fxLayout="row wrap" fxLayoutAlign.gt-xs="center center" fxLayoutAlign.xs="start start"
                       fxLayoutGap="5px" ngClass.xs="p-b-35">
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      온투업 투자
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      온투업 사업자
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      대출 부동산
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      투자 부동산
                    </div>
                    <div ngClass.gt-xs="bg-chip-lg m-b-7" ngClass.xs="bg-chip-xs m-b-5">
                      담보 현황 안내
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <!--          로그아웃 상태의 서비스 소개 영역 끝-->
          }
        </app-swiper>
      </div>
    </section>
  </div>
  <!--서비스 소개 끝-->

  @if (!isLogin) {
    <section class="narrow-section min-h-10 p-v-0" ngClass.gt-xs="m-t-m120">
      <div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center" fxLayout.xs="column"
           ngClass.gt-xs="bg-01 border-5 p-v-50 p-h-60 brd-150" ngClass.xs="p-h-20">
        <div fxFlex fxFlex.gt-xs="64">
          <div ngClass.xs="text-center">
            <div ngClass.gt-xs="mat-headline-4" ngClass.xs="mat-subtitle-2 f-w-700 m-t-30 m-b-20">
              체크팀<span fxHide fxShow.gt-xs> 부동산</span> 서비스를 소개합니다!
            </div>
          </div>
        </div>
        <div fxFlex fxFlex.gt-xs="36" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.xs="space-between center"
               class="text-center cursor brd-82">
            <div fxFlex routerLink="/analysis/info" fxLayout="column" class="text-center align-clean hover-line">
              <div ngClass.gt-xs="box-circle" ngClass.xs="box-circle-white-xs" class="m-b-6">
                <img alt="권리분석" ngClass.xs="img-size-35" src="assets/img/service/main/ic_48_memo.svg"/>
              </div>
              <div ngClass.gt-xs="mat-body-1 f-w-700" ngClass.xs="f-w-800">
                권리 분석
              </div>
            </div>

            <div fxFlex routerLink="/info/my-house" fxLayout="column" class="text-center align-clean hover-line">
              <div ngClass.gt-xs="box-circle" ngClass.xs="box-circle-white-xs" class="m-b-6">
                <img alt="등기변경알림" ngClass.xs="img-size-35" src="assets/img/service/main/main-intro-bell.svg"/>
              </div>
              <div ngClass.gt-xs="mat-body-1 f-w-700" ngClass.xs="f-w-800">
                등기변경 알림
              </div><!--(login전)-->
            </div>

            <div fxFlex routerLink="/info/open-house" fxLayout="column" class="text-center align-clean hover-line">
              <div ngClass.gt-xs="box-circle" ngClass.xs="box-circle-white-xs" class="m-b-6">
                <img alt="오픈부동산" ngClass.xs="img-size-35" src="assets/img/service/main/ic_48_calendar.svg"/>
              </div>
              <div ngClass.gt-xs="mat-body-1 f-w-700" ngClass.xs="f-w-800">
                오픈 부동산
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
  <!--퀵 메뉴-->
  <section class="narrow-section min-h-10 p-v-0">
    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="12px" ngClass.gt-xs="p-b-50 p-t-50"
      ngClass.xs="p-t-35 p-b-15 p-h-20">
      <div fxFlex="100" fxFlex.gt-xs="25">
        <div class="cursor">
          @if (isLogin) {
          <div fxLayout="column" ngClass.gt-xs="bg-white p-l-30 p-r-5 p-t-40 p-b-35 brd-16 hover-primary-light"
            ngClass.xs="bg-white p-h-30 p-v-20 brd-16 m-b-30">
            <div>
              <img alt="로그인" src="assets/img/service/main/ic_1.svg" />
            </div>
            <div>
              <div fxLayout="row" fxLayoutAlign="start center" ngClass.gt-xs="m-b-5 p-t-15" ngClass.xs="p-t-10 m-b-4">
                <div ngClass.gt-xs="mat-subtitle-1 f-w-700" ngClass.xs="mat-subtitle-3 f-w-800">
                  마이 부동산
                </div>
              </div>

              <div ngClass.gt-xs="mat-body-2 p-b-3" ngClass.xs="mat-body-1 p-b-10">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div routerLink="/analysis/list" class="f-w-600">
                    권리분석
                    <span class="p-l-1 f-w-700 count-point">{{
                      counts.analysis | number
                      }}</span>
                    <span class="p-l-1 f-12">({{ counts.analysisPending | number }})</span>
                    <span class="material-symbols-outlined f-24 f-w-800">chevron_right</span>
                  </div>

                  <div routerLink="/house/list" class="f-w-600">
                    등기변경 알림
                    <span class="p-l-1 f-w-700 count-point">{{
                      counts.house | number
                      }}</span>
                    <span class="material-symbols-outlined f-24 f-w-800" ngClass.xs="c-gray-deep">chevron_right</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          } @else {
          <div fxLayout="column" ngClass.gt-xs="bg-white p-h-30 p-v-40 brd-16 cursor hover-primary-light"
            ngClass.xs="bg-white p-h-30 p-v-20 brd-16 m-b-30" routerLink="/analysis/list">
            <div>
              <img alt="마이부동산" src="assets/img/service/main/ic_1.svg" />
            </div>
            <div>
              <div fxLayout.gt-xs="row" fxLayoutAlign="start center" ngClass.gt-xs="m-b-5 p-t-15" ngClass.xs="p-t-10 m-b-4">
                <div ngClass.gt-xs="mat-subtitle-1 f-w-700" ngClass.xs="mat-subtitle-3 f-w-700">
                  마이 부동산
                </div>
                <span fxShow.gt-xs fxHide class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right.svg" alt="더보기"></span>
                <!-- <span class="material-symbols-outlined f-24 f-w-700">chevron_right</span> -->
              </div>

              <div ngClass.gt-xs="mat-body-2 f-w-500 p-b-3" ngClass.xs="f-w-600 p-b-10">
                <div routerLink="/analysis/list">
                  로그인 후 확인할 수 있습니다.
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>

      <div fxFlex="100" fxFlex.gt-xs="25">
        <div fxLayout="column" ngClass.gt-xs="bg-white p-l-30 p-r-20 p-v-40 brd-16 cursor hover-primary-light"
          ngClass.xs="bg-white p-h-30 p-v-30 brd-16 m-b-30" routerLink="/analysis">
          <div>
            <img alt="신규권리분석" src="assets/img/service/main/ic_2.svg" />
          </div>
          <div>
            <div fxLayout.gt-xs="row" fxLayoutAlign="start center" ngClass.gt-xs="m-b-5 p-t-15" ngClass.xs="p-t-10 m-b-4">
              <div ngClass.gt-xs="mat-subtitle-1 f-w-700" ngClass.xs="mat-subtitle-3 f-w-700">
                부동산 신규 권리분석
              </div>
              <span fxShow.gt-xs fxHide class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right.svg" alt="더보기"></span>
              <!-- <span class="material-symbols-outlined f-24 f-w-700">chevron_right</span> -->
            </div>

            <div ngClass.gt-xs="f-w-500 p-b-3" ngClass.xs="f-w-600">
              담보분석 &middot; 전세보증금 진단 &middot; 경매 배당표
            </div>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.gt-xs="25">
        <div fxLayout="column" ngClass.gt-xs="bg-white p-l-30 p-r-5 p-v-40 brd-16 cursor hover-primary-light"
          ngClass.xs="bg-white p-h-30 p-v-20 brd-16 m-b-30" [queryParams]="{ type: 'MY_HOUSE' }"
          routerLink="/house/register">
          <div>
            <img alt="부동산등기변경알림신청" src="assets/img/service/main/ic_4.svg" />
          </div>
          <div>
            <div fxLayout.gt-xs="row" fxLayoutAlign="start center" ngClass.gt-xs="m-b-5 p-t-15" ngClass.xs="p-t-10 m-b-4">
              <div ngClass.gt-xs="mat-subtitle-1 f-w-700" ngClass.xs="mat-subtitle-3 f-w-700">
                부동산 등기변경알림신청
              </div>
              <span fxShow.gt-xs fxHide class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right.svg" alt="더보기"></span>
              <!-- <span class="material-symbols-outlined f-24 f-w-700">chevron_right</span> -->
            </div>
            <div ngClass.gt-xs="f-w-500 p-b-3" ngClass.xs="f-w-600">
              등기 변경알림 &middot; 부동산등본 열람
            </div>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.gt-xs="25">
        <div fxLayout="column" ngClass.gt-xs="bg-white p-l-30 p-r-20 p-v-40 brd-16 cursor hover-primary-light"
          ngClass.xs="bg-white p-h-30 p-v-30 brd-16 m-b-30" routerLink="/house/open">
          <div>
            <img alt="오픈부동산" src="assets/img/service/main/ic_3.svg" />
          </div>
          <div>
            <div fxLayout.gt-xs="row" fxLayoutAlign="start center" ngClass.gt-xs="m-b-5 p-t-15" ngClass.xs="p-t-10 m-b-4">
              <div ngClass.gt-xs="mat-subtitle-1 f-w-700" ngClass.xs="mat-subtitle-3 f-w-700">
                오픈 부동산 알림 신청
              </div>
              <span fxShow.gt-xs fxHide class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right.svg" alt="더보기"></span>
              <!-- <span class="material-symbols-outlined f-24 f-w-700">chevron_right</span> -->
            </div>
            <div ngClass.gt-xs="mat-body-2 f-w-500 p-b-3" ngClass.xs="f-w-600">
              온투업 투자부동산 담보현황 자동안내
            </div>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxFlex.gt-xs="25" fxShow fxHide.gt-xs>
        <div fxLayout="column" ngClass.gt-xs="bg-white p-l-30 p-r-20 p-v-40 brd-16 cursor hover-primary-light"
             ngClass.xs="bg-white p-h-30 p-v-30 brd-16 m-b-30" routerLink="/calculator">
          <div>
            <img alt="오픈부동산" src="assets/img/service/main/ic_2.svg" />
          </div>
          <div>
            <div fxLayout.gt-xs="row" fxLayoutAlign="start center" ngClass.gt-xs="m-b-5 p-t-15" ngClass.xs="p-t-10 m-b-4">
              <div ngClass.gt-xs="mat-subtitle-1" ngClass.xs="mat-subtitle-3 f-w-800">
                부동산 계산기
              </div>
              <span fxShow.gt-xs fxHide class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right.svg" alt="더보기"></span>
            </div>

            <div ngClass.gt-xs="f-w-500 p-b-3" ngClass.xs="f-w-600">
              증여세 &middot; 양도세 &middot; 보유세 등
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <!--퀵 메뉴 끝-->
</div>


<!--권리분석 쿠폰신청 시작-->
<div ngClass.gt-xs="p-v-50 bg-02">
  <section class="narrow-section min-h-10 p-v-0">
    <div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center" fxLayout.xs="column" fxLayoutGap="20px"
      ngClass.gt-xs="bg-white brd-15" ngClass.xs="p-h-25 p-b-40">

      <div fxFlex fxFlex.gt-xs="50" >
        <img ngClass.gt-xs="img-radius-left m-b-m5" ngClass.xs="w-100 m-t-25" alt="권리분석 베타서비스"
             src="assets/img/service/main/main-img-building.png">
      </div>

      <div fxFlex fxFlex.gt-xs="50">
        <div ngClass.gt-xs="p-v-88 p-h-60">
          <div ngClass.gt-xs="p-b-40">
            <div ngClass.gt-xs="mat-subtitle-3 p-b-15" ngClass.xs="mat-body-1 p-b-15">
              권리분석 베타 서비스
            </div>
            <div ngClass.gt-xs="mat-headline-5 f-w-700" ngClass.xs="mat-subtitle-1 p-b-10">
              체크팀이 부동산 권리분석<br />서비스를 시작합니다!
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="20px" class="c-accent">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.gt-xs="2px" fxLayoutGap.xs="2px">
              <div ngClass.gt-xs="cursor hover-line mat-subtitle-2" ngClass.xs="cursor mat-body-1" routerLink="/analysis/info">
                내용 보기
              </div>
              <span class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right.svg" alt="더보기"></span>
              <!-- <span class="material-symbols-outlined f-24 f-w-800">chevron_right</span> -->
            </div>
            <a href="https://forms.gle/wCSsPUSMbyMATCai7" target="_blank">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.gt-xs="2px" fxLayoutGap.xs="2px">
                <div ngClass.gt-xs="cursor hover-line mat-subtitle-2" ngClass.xs="mat-body-1" routerLink="/analysis/info">
                  무료 분석 쿠폰 받기
                </div>
                <span class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right.svg" alt="더보기"></span>
                <!-- <span class="material-symbols-outlined f-24 f-w-800">chevron_right</span> -->
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!--권리분석 쿠폰신청 끝-->

<div>
  <section class="narrow-section min-h-10" fxLayout="column" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-around center" fxLayoutGap="30px" fxLayoutGap.gt-xs="40px" ngClass.gt-xs="m-b-15"
    ngClass.xs="bg-gray-light">
    <div fxLayout.gt-xs="row" fxLayout.xs="column" ngClass.gt-xs="p-v-20 w-100" ngClass.xs="p-h-25">
      <div fxFlex.gt-xs="50">
        <div ngClass.gt-xs="p-t-40" ngClass.xs="p-t-30 p-b-40">
          <div ngClass.gt-xs="mat-headline-5 f-w-640 m-b-24" ngClass.xs="mat-subtitle-1 p-b-10">
            온투업 부동산 투자상품의<br />
            담보정보를 무료로 확인하세요!
          </div>
          <div ngClass.gt-xs="mat-subtitle-3 lh-1-9 m-b-5 w-70" ngClass.xs="mat-body-1 m-b-5">
            투자자는 '알림신청'만으로 투자부동산의
            <div fxShow.gt-xs fxHide></div>
            담보현황을 확인할 수 있습니다.
          </div>

          <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutGap="20px" class="c-accent" ngClass.gt-xs="p-t-44" ngClass.xs="p-t-30">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.gt-xs="2px" fxLayoutGap.xs="2px">
              <div ngClass.gt-xs="cursor hover-line mat-subtitle-2" ngClass.xs="cursor mat-body-1" routerLink="/info/open-house">
                서비스 소개
              </div>
              <span class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right.svg" alt="더보기"></span>
              <!-- <span class="material-symbols-outlined f-24 f-w-800">chevron_right</span> -->
            </div>

            <a href="https://forms.gle/wCSsPUSMbyMATCai7" target="_blank">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.gt-xs="2px" fxLayoutGap.xs="2px">
                <div ngClass.gt-xs="cursor hover-line mat-subtitle-2" ngClass.xs="cursor mat-body-1" routerLink="/house/open">
                  오픈부동산 보기
                </div>
                <span class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right.svg" alt="더보기"></span>
                <!-- <span class="material-symbols-outlined f-24 f-w-800">chevron_right</span> -->
              </div>
            </a>
          </div>
        </div>
      </div>

      <div fxFlex.gt-xs="40">
        <div ngClass.gt-xs="p-v-20 text-center">
          <div routerLink="/info/open-house">
            <div class="cursor" fxLayout="row" fxLayoutGap="1px">
              <div fxFlex fxLayoutAlign="center center">
                <div>
                  <img src="assets/img/service/main/logo-8percent.svg" alt="파트너" ngClass.xs="img-size-95p"
                    ngClass.gt-xs="img-size-128" />
                </div>
              </div>
              <div fxFlex fxLayoutAlign="center center">
                <div>
                  <img src="assets/img/service/main/logo-cocktail.svg" alt="파트너" ngClass.xs="img-size-95p"
                    ngClass.gt-xs="img-size-128" />
                </div>
              </div>
              <div fxFlex fxLayoutAlign="center center">
                <div>
                  <img src="assets/img/service/main/logo-zoom.svg" alt="파트너" ngClass.xs="img-size-95p"
                    ngClass.gt-xs="img-size-128" />
                </div>
              </div>
              <div fxFlex fxLayoutAlign="center center">
                <div>
                  <img src="assets/img/service/main/logo-leading.svg" alt="파트너" ngClass.xs="img-size-95p"
                    ngClass.gt-xs="img-size-128" />
                </div>
              </div>
            </div>

            <div class="cursor m-t-m3" fxLayout="row" fxLayoutGap="1px">
              <div fxFlex fxLayoutAlign="center center">
                <div>
                  <img src="assets/img/service/main/logo-daily.svg" alt="파트너" ngClass.xs="img-size-95p"
                       ngClass.gt-xs="img-size-128" />
                </div>
              </div>
              <div fxFlex fxLayoutAlign="center center">
                <div>
                  <img src="assets/img/service/main/logo-fm.svg" alt="파트너" ngClass.xs="img-size-95p"
                       ngClass.gt-xs="img-size-128" />
                </div>
              </div>
              <div fxFlex fxLayoutAlign="center center">
                <div>
                  <img src="assets/img/service/main/logo-ace.svg" alt="파트너" ngClass.xs="img-size-95p"
                       ngClass.gt-xs="img-size-128" />
                </div>
              </div>
              <div fxFlex fxLayoutAlign="center center">
                <div></div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div fxShow fxHide.gt-xs fxLayout="row" fxLayoutGap="20px" class="c-accent" ngClass.gt-xs="p-t-44" ngClass.xs="p-t-10">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.gt-xs="2px" fxLayoutGap.xs="2px">
          <div ngClass.gt-xs="cursor hover-line mat-subtitle-2" ngClass.xs="cursor mat-body-1" routerLink="/info/open-house">서비스 소개</div>
          <span class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right_02.svg" alt="더보기"></span>
        </div>
        <a href="https://forms.gle/wCSsPUSMbyMATCai7" target="_blank">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.gt-xs="2px" fxLayoutGap.xs="2px">
            <div ngClass.gt-xs="cursor hover-line mat-subtitle-2" ngClass.xs="cursor mat-body-1" routerLink="/house/open">오픈부동산 보기</div>
            <span class="btn-more"><img src="./assets/img/service/main/btn_24_arr_right_02.svg" alt="더보기"></span>
          </div>
        </a>
      </div>
    </div>
  </section>
</div>

<div *ngIf="showStoreLink" ngClass.gt-xs="bg-primary-light p-v-50" ngClass.xs="bg-primary-light p-v-40 p-h-25">
  <section class="narrow-section min-h-10 p-v-0">
    <div>
      <div class="p-b-40" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap.xs="20px" fxLayoutGap.gt-xs="10px">
        <img (click)="openPlayStore()" alt="Play Store Link" ngClass.gt-xs="cursor img-size-64" fxFlex.xs="50"
          src="assets/img/store/down_google.svg" />
        <img (click)="openAppStore()" alt="AppStore Link" ngClass.gt-xs="cursor img-size-64" fxFlex.xs="50"
          src="assets/img/store/down_appstore.svg" />
      </div>
      <div ngClass.gt-xs="mat-subtitle-1 lh-1-4 f-w-400 text-center" ngClass.xs="mat-body-1 lh-1-7 f-w-450 text-center">
        <span class="f-w-650">체크팀 앱</span>을 다운받으시면
        <span class="f-w-650">등기변경 안내를 앱(푸시) 알림</span>으로도 확인할 수
        있습니다.
      </div>
    </div>
  </section>
</div>

<app-footer></app-footer>
